import React, { useEffect } from "react";
import Link from "next/link";

import SearchBar from "src/components/index/SearchBar";
import BrowseAndExploreBanner from "src/components/index/BrowseAndExploreBanner";

const NotFound = () => {
  useEffect(() => {
    const metaRobots = document.createElement("meta");
    metaRobots.name = "robots";
    metaRobots.content = "noindex";
    document.head.appendChild(metaRobots);
  }, []);

  return (
    <div className="index-home-page-wrapper not-found content-min-height">
      <div className="homepage-content container">
        <div className="index-homepage-header">
          <h1>
            Oops!&nbsp;
            <span className="break-together">We couldn't find that...</span>
          </h1>
          <h2>
            please try searching HASH, or&nbsp;
            <Link href="/search?sort=popularity">
              <a className="text-link break-together">view trending projects</a>
            </Link>
            .
          </h2>
        </div>
        <div className="homepage-searchbar">
          <SearchBar />
        </div>
      </div>

      <BrowseAndExploreBanner />
    </div>
  );
};

export default NotFound;
